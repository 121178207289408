/* CSS Document */
 * {
     padding:0;
     margin:0;
     list-style:none;
}
 html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
     margin: 0;
     padding: 0;
     border: 0;
     outline: 0;
     font-size: 100%;
}
/* remember to define focus styles! */
 :focus {
     outline: 0;
}
 ol, ul {
     list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
 table {
     border-collapse: separate;
     border-spacing: 0;
}
 caption, th, td {
     text-align: left;
     font-weight: normal;
}
 blockquote:before, blockquote:after, q:before, q:after {
     content: "";
}
 blockquote, q {
     quotes: "" "";
}
 body {
     font-family: 'ITC Avant Garde Std Md';
     background:#fff;
     font-size:100%;
     color:#3E3E3E;
     line-height:100%;
     font-style:normal;
     font-weight:500;
}
 input {
     background:none;
     border:0 none;
     outline:none;
}
 table {
     border:0 none;
     border-collapse:collapse;
     border-spacing:0;
}
 td {
     vertical-align:top;
}
 img {
     border:0 none;
     max-width:100%;
     max-height:100%;
    height:auto;
    width:auto;
    display:block;
    margin:0 auto;
}
 a {
     outline:none;
     cursor:pointer;
    text-decoration:none;
    display:block;
}
 article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
     display:block;
}
/*responsive menu*/
 .menu {
    color: #333333;
     cursor: pointer;
     float: left;
     font-family: 'Lato',sans-serif;
     font-size: 22px;
     line-height: 31px;
     display:none;
}
 .menu .menu-trigger {
     float: left;
     margin: 8px 20px 0 25px;
}
 .menu .line:nth-child(1):after {
     transition: all 0.6s linear 0.1s;
}
 .menu .line:after {
     background: #FFFFFF;
     content: "";
     display: block;
     height: 3px;
     width: 0;
}
 .menu .line {
     background: #333333;
     display: block;
     height: 3px;
     margin-bottom: 5px;
     width: 33px;
}
 .header-sec .menu-wrap .menu:hover .line:after {
     transform: rotate(360deg);
     width: 100%;
}
 .show-menu .side-menu {
     transition: all 1.4s ease 0s;
     z-index: 1;
}
 .side-menu {
    display:none;
     background:#333333;
     height: 100%;
     overflow: hidden;
     padding: 69px 50px 0;
     position: fixed;
     right: 0;
     top: 0;
     transition: all 0.4s ease 0s;
     width: 225px;
     z-index: -1;
}
 .show-menu #wrapper {
     transform: translateX(-325px);
}
 #wrapper {
     background: #FFFFFF;
     position: relative;
     transition: all 0.3s ease 0s;
     z-index: 99999;
}
/*responsive menu end */
 .container {
     margin:0 auto !important;
     max-width:1110px !important;
     width: 96% !important;
}
.btn {
    font-family: 'ITC Avant Garde Std Md';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;
    color: #FFFFFF;
    display: inline-block;
    padding: 13px 61px;
    position: relative;
    cursor: pointer;
	transition: 0.3s all ease-in-out;
}
.btn:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    border: 1px solid #78b06e;
    left: 5px;
    top: 5px;
}
.btn:hover
{
	background: #f5f5f5;
	color: #335480;
}
.btn:hover:after
{
	border: 1px solid #f5f5f5;
}
.btn-primary
{
	background: #78B06E;
}
.btn-secondary
{
	background: #335480;
}
.title
{
	font-family: 'ITC Avant Garde Std Bk' !important;
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 40px !important;
	line-height: 160% !important;
	color: #335480 !important;
	text-align: center !important;
}
.title-white
{
	color: #fff !important;
}
p{
	font-family: 'ITC Avant Garde Std Bk';
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 160%;
	text-align: center;
}
/**************** Header ****************/
.inner-header {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}
.logo img {
    width: 160px;
    height: 105px;
}
.mainmenu ul {
    display: flex;
    justify-content: flex-end;
}
.mainmenu ul li a {
     font-family: 'ITC Avant Garde Std Md';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #335480;
}
.mainmenu ul li a {
    font-family: 'ITC Avant Garde Std Md';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #335480;
    padding: 25px 40px;
	transition: 0.3s all ease-in-out;
}
.mainmenu ul li a:hover,.mainmenu ul li.active a
{
	color: #78B06E;
}
.search-img {
    padding: 22px 0 28px 40px;
    display: inline-block;
}
.header-banner {
    display: flex;
    align-items: center;
    margin-top: -15px;
	padding-bottom: 50px;
}
.header-banner-left {
    max-width: 391px;
    border: 2px solid #335480;
    margin-right: 44px;
}
.content-row .row .detail-left ul li {
    width: 100% !important;
}
.header-banner-left {
    max-width: 391px;
    max-height: 331px;
    border: 2px solid #335480;
    margin-right: 44px;
    padding-top: 45px;
	padding-bottom: 45px;
    position: relative;
}
.header-banner-left h4 {
    font-family: 'ITC Avant Garde Std Bk';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 160%;
    color: #335480;
    margin-bottom: 20px;
    transform: rotate(6deg);
    padding-left: 10px;
}
.header-banner-left h3
{
	font-family: 'ITC Avant Garde Std Bk';
	font-style: normal;
	font-weight: 300;
	font-size: 30px;
	line-height: 160%;
	color: #335480;
	transform: rotate(6deg);
}
.header-banner-left h3 span {
    color: #78B06E;
    font-weight: 600;
    font-family: 'ITC Avant Garde Std Bk';
    text-transform: uppercase;
}
.left-content {
    background: #fff;
    width: 105%;
    margin-left: -5%;
    padding: 20px 13px 0;
    transform: rotate(-6deg);
	position: relative;
}
.left-content:before {
    position: absolute;
    content: "";
    height: 41px;
    width: 100%;
    background: white;
    top: -20px;
    transform: rotate(6deg);
}
.content-block-1 {
    padding-top: 70px;
    padding-bottom: 100px;
    margin-bottom: 00px;
    background: #f0f7f8;
    position: relative;
}


.content-block-1:after
{
	position: absolute;
    content: "";
    height: 250px;
    width: 100%;
    background: url(../images/shape.png) no-repeat;
    bottom: -250px;
}

.why-consuting-top p {
    max-width: 730px;
    margin: 0 auto 30px;
}
.why-consuting-top .title {
    margin-bottom: 30px;
}
/**************** Close ****************/
/**************** Content ****************/
.why-consuting-bottom {
    display: flex;
    margin-top: 70px;
	justify-content: space-between;
	
}
.consulting-box {
    width: 22%;
    text-align: center;
}
.consulting-box span {
    min-height: 100px;
    display: inline-block;
    margin-bottom: 30px;
}
.consulting-box h4 {
    font-family: 'ITC Avant Garde Std Md';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    color: #335480;
    margin-bottom: 20px;
}
.vid-inner {
    display: flex;
    align-items: flex-start;
}
.video-sec-left {
    width: 50%;
}
.video-sec-right {
    width: 50%;
    background: #335480;
    padding: 45px;
    margin-top: -50px;
}
.video-sec-right h4 {
    font-family: 'ITC Avant Garde Std Md';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: #FFFFFF;
    max-width: 436px;
    margin-bottom: 20px;
}
.video-sec-right p {
    color: #FFFFFF;
    text-align: left;
	margin-bottom: 30px;
}
.inner-video span {
    width: 86px;
    height: 86px;
    background: #78b06e;
    display: flex;
    align-items: center;
    margin-top: -86px;
    position: relative;
}
.service-sec {
    background: url(../images/banner-img.jpg) no-repeat;
    background-attachment: fixed;
    padding: 100px 0;
    background-size: cover;
    position: relative;
}
.service-inner {
    display: flex;
    justify-content: space-around;
    position: relative;
}
.service-inner-left .title {
    margin-top: 30px;
	
}
.right-side-bullet li {
     font-family: 'ITC Avant Garde Std Md';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 300%;
    color: #335480;
    position: relative;
    padding-left: 50px;
}
.right-side-bullet li:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 22px;
    background: url(../images/service-arrow.png) no-repeat;
    left: 0;
    top: 20px;
}
.service-sec:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,0.7);
    top: 0;
}
.video-section {
    padding-bottom: 50px;
}

/**************** Close ****************/
/**************** Footer ****************/
/**************** Close ****************/
.student-love-us {
    background: #F0F7F8;
    padding-top: 65px;
}
.student-love-us .title {
    margin-bottom: 45px;
}
 .student-testimonial-wrapper {
    display: flex;
}
.student-testi-left, .student-testi-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 110px 60px;
	position: relative;
}
.student-name span {
    display: flex;
    margin-left: 20px;
}
.student-name {
    display: flex;
    margin-top: 20px;
    align-items: center;
}
.student-testi-left p,.student-testi-right p {
    text-align: left;
}
.student-name span img {
    margin-left: 5px;
}
.student-testi-left p span, .student-testi-right p span {
    font-weight: 600;
	font-family: 'ITC Avant Garde Std Md';
	font-style: italic;
}
.student-testi-left:before,.student-testi-right:before
{
	position:absolute;
	content:"";
	width: 60px;
	height: 41px;
	background: url("../images/quotes.png") no-repeat;
	left: 10px;
	top: 0;
} 
.btn-wrapper {
    margin-top: 50px;
    text-align: center;
}
.btn-wrapper .btn-primary {
    padding: 13px 80px;
}
.before-footer {
    background: #335480;
    padding: 60px 0;
    text-align: center;
}
.before-footer-inner .title {
    margin-bottom: 30px !important;
}

/******** Footer ********/
#footer {
    background: #3E3E3E;
    padding: 30px 0;
}
.footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-left ul {
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-left ul li, .footer-left ul li a {
    display: flex;
    align-items: center;
    color: #fff;
	font-family: 'ITC Avant Garde Std Bk';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 160%;
/* or 22px */


color: #FFFFFF;
}
.footer-left {
    width: 100%;
}
.footer-right {
    width: 30%;
}
.social-media {
    display: flex;
	justify-content: flex-end;
}
.social-media a {
    color: #fff;
    width: 30px;
    border: 2px solid;
    text-align: center;
    margin: 0 10px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    transition: 0.3s all ease-in-out;
}
.social-media a:hover {
    background: #78b06e;
    border-color: #78b06e;
}
.footer-left ul li a:hover
{
	color: #78b06e;
}
.footer-left ul li {
    margin-right: 50px;
}
.footer-left ul li:last-child
{
	margin-right: 0;
}
.testimonial-btn {
    text-align: center;
    padding-bottom: 30px;
}
.testimonial-btn button {
    background: none;
    margin: 0 10px;
}
span.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #C9D8DA;
    margin: 0 5px;
    border-radius: 50%;
}
span.dot.active
{
    background: #78b06e;
}

.testimonial-btn {
    text-align: center;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination {
    margin: 0 25px;
}
 /* 
 font-family: 'ITC Avant Garde Std Md';
 font-family: 'ITC Avant Garde Std Bk';
 font-family: 'ITC Avant Garde Std Md';
*/

.pdf-view {
    max-width: 1110px;
    margin: 10px auto 50px;
    border: 1px solid;
	padding: 15px;
	height: 700px;
	overflow: auto;
}
.pdf-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1110px;
    margin: 0 auto;
    background: #345580;
    padding: 10px 15px;
    order: -1;
    margin-top: 50px;
}
.pdf-page {
    display: flex;
    color: #fff;
}
.pdf-page p {
    margin: 0 10px;
    letter-spacing: 1px;
}
.pdf-page button {
    background: none;
    color: #fff;
    font-size: 16px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}
.pdf-zoom button {
    margin-right: 10px;
    font-size: 24px;
    color: #335480;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}
.pdf-page button:hover,.pdf-zoom button:hover
{
    opacity: 0.8;
}