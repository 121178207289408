/* Responsive */
@media(max-width: 1109px)
{
	.container {
    margin: 0 auto !important;
    max-width: 1110px !important;
    width: 96% !important;
}
.consulting-box h4 {
    font-size: 18px !important;
}
.student-testi-left, .student-testi-right {
    padding: 0 90px 60px !important;
    
}
}
@media(max-width: 1024px)
{
	.footer-left ul li {
    margin-right: 25px !important;
}
#footer {
    padding: 15px 0 !important;
}
.student-testi-left, .student-testi-right {
    padding: 0 75px 60px !important;
}
.login-right .student-testi-left {
    width: 100% !important;
    padding: 0 !important;
}
.student-testi-left:before, .student-testi-right:before {
    left: -74px !important;
   
}
.filter-block button {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
}
@media(max-width: 991px)
{
	.why-consuting-bottom {
		flex-wrap: wrap !important;
	}
	.consulting-box 
	{
		width: 48% !important;
		text-align: center !important;
		margin-bottom: 30px !important;
	}
	.content-block-1 
	{
		padding-bottom: 20px !important;
	}
	.student-testimonial-wrapper {
		display: flex !important;
		flex-direction: column !important;
	}
	.student-testi-left, .student-testi-right 
	{
		padding: 0 15px 60px 95px !important;
		width: 86% !important;
	}
	.footer-inner {
		flex-direction: column !important;
	}
	.footer-left {
		width: 100% !important;
		
	}
	.footer-left ul 
	{
		justify-content: center !important;
    }
    .why-consuting {
        margin-bottom: 70px !important;
    }
	.content-block-1.con 
	{
		height: 300px !important;
		margin-bottom: 500px !important;
	}
	.student-testi-left:before, .student-testi-right:before {
		background-size: 50px !important;
		left: 20px !important;
	}
	.tutorial-block:nth-child(2) {
    padding-left: 5% !important;
}
.detail-left ul li h4 {
    
    width: 27% !important;
}


/******** Mobile Responsive ********/
.phone-menu
{    
    
    flex-direction: column !important;
    min-width: 300px !important;
    background: #FFFFFF !important;
    box-shadow: -10px 30px 24px rgba(0, 0, 0, 0.25) !important;
    justify-content: center !important;
    position: absolute !important;
    right: -300px !important;
    top: 60px !important;
    padding: 15px 0 !important;
    transition: 0.5s all ease-in-out !important;

}
.phone-menu.show-menu
{
    right: 0 !important ;
    display: flex !important ;
    z-index: 9999 !important;
}
.mainmenu ul li {
    text-align: center !important;
}
.logo img {
    width: 100px  !important;
    height: auto !important ;
}
.logo img {
    max-width: 100% ;
    height: auto ;
    width: 100% ;
}
.inner-header {
    display: flex !important;
    justify-content: space-between !important;
    padding-top: 5px !important;
    padding: 5px 20px 20px !important;
    align-items: center !important;
}
}
@media(max-width: 767px)
{
	.header-banner {
		flex-direction: column !important;
	}
	.header-banner-left {
		margin-right: 0 !important;
	}
	.header-banner-right {
    order: -1 !important;
    margin-bottom: 30px !important;
}
.content-block-1 {
    padding-top: 30px !important;
}
.title {
    font-size: 30px !important;
    line-height: 120% !important;
}
.vid-inner {
    flex-direction: column !important;
}
.video-sec-left {
    width: 100% !important;
}
.video-sec-right {
    width: 93% !important;
    padding: 30px 15px 200px !important;
    margin-top: 0 !important;
    order: -1 !important;
}
.video-sec-left {
    width: 94% !important;
    margin: -150px auto 0 !important;
    position: relative !important;
    z-index: 99 !important;
}

.service-inner {
    flex-direction: column !important;
}
.service-sec {
    padding: 30px 0 50px !important;
}
.service-inner-left .title {
    margin-bottom: 20px !important;
}
.service-inner-left p {
    font-size: 24px !important;
}
.student-testi-left, .student-testi-right {
    padding: 0 15px 60px 95px !important;
    width: 70% !important;
}
.service-sec.ser-2 {
    padding-top: 70px !important;
}
.footer-left ul {
    flex-direction: column !important;
}
.footer-left ul li {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
}
.footer-left ul li:first-child {
    order: 3 !important;
}
.footer-right {
    width: 100% !important;
}
.social-media {
    justify-content: center !important;
}
.footer-left {
    width: 100% !important;
    margin-bottom: 10px !important;
}
.right-side-bullet {
    margin-top: 30px !important;
}
.content-block-1 {
    padding-top: 30px !important;
}
.content-block-1.con {
    height: auto !important;
    margin-bottom: 50px !important;
}
.contact-block-bottom {
    padding-top: 35px !important;
    flex-direction: column !important;
}
.contact-left {
    width: 100% !important;
}
.contact-left li > span {
    width: 93% !important;
}
.contact-right {
    width: 85% !important;
    
}
.contact-right {
    width: 94% !important;
    padding: 30px 15px !important;
   
}
.form-field-wrapper {
    margin-bottom: 0 !important;
    flex-direction: column !important;
}
.form-field {
    width: 100% !important;
    margin-bottom: 30px !important;
}
.contact-right .btn-wrapper {
    text-align: right !important;
    margin-top: 0 !important;
}
.contact-right .btn-wrapper .btn-primary {
    padding: 13px 0 !important;
    width: 100% !important;
}
.tutorial-block-wrapper {
    flex-direction: column !important;
}
.tutorial-block {
    position: relative !important;
    z-index: 99 !important;
    width: 100% !important;
}
.tutorial-block:nth-child(2) {
    padding-left: 0 !important;
}
.content-block-1.con.resource {
    height: auto !important;
    margin-bottom: 70px !important;
}
.right-side-bullet {
    margin-top: 20px !important;
}
.video-block {
    width: calc(100% / 2 - 10px) !important;
    margin: 0 0px 15px 0 !important;
}
.video-block:nth-child(3n+3) {
    margin-right: 15px !important;
}
.video-block:nth-child(2n+2)
{
	 margin-right: 0px !important;
}
.video-block-wrapper {
    justify-content: space-between !important;
}
.login-wrapper:after {
    height: 45% !important;
    width: 100% !important;
    right: 0 !important;
    left: auto !important;
    top: auto !important;
    bottom: 0 !important;
}
.login-block {
    padding: 20px 0 0 !important;
    flex-direction: column !important;
}
.login-left {
    width: 100% !important;
   
}
.login-right {
    width: 100% !important;
}

.login-right {
    width: 80% !important;
    padding-top: 90px !important;
    padding-left: 15% !important;
}
.login-right .student-testi-left:before {
    top: 40px !important;
    left: -62px !important;
}
.login-right span {
    padding-bottom: 20px !important;
}
.data-filter {
    flex-direction: column !important;
}
.filter-block {
    width: 96% !important;
    padding: 20px 1.9% 12px !important;
    border-right: none !important;
    border-bottom: 1px solid #e5e5e5 !important;
}
.chart-desc {
    display: flex !important;
    align-items: flex-start !important;
    padding-bottom: 20px !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
}
.chart-desc p {
    max-width: 100% !important;
    margin: 0 !important;
    text-align: left !important;
}
.data {
    padding-top: 20px !important;
}
.detail-box {
    flex-direction: column !important;
}
.detail-left {
    width: 100% !important;
}
.detail-right {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
}
.btn-wrapper.mobile-display {
    display: block !important;
	margin-top: 20px !important;
}
.btn-wrapper.desktop-display {
    display: none !important;
}
.student-testi-left:before, .student-testi-right:before {
    background-size: 50px !important;
    left: 0 !important;
}
.notlogin {
    padding: 20px 10px !important;
}
.login-right {
    padding-top: 50px !important;
    
}
.mobile-show {
    display: flex !important;
    margin-bottom: 20px !important;
	align-items: center !important;
}
h5.mobile-progress {
    padding-left: 0  !important;
    width: auto  !important;
    margin-right: 20px !important;
}
h5.mobile-hide {
    display: none !important;
}
.mobile-show .mobile-pro
{
    width: 160px !important;
    height: 10px !important;
    display: flex !important;
    background: #E1EAEB !important;
    position: relative !important;
    margin-right: 30px !important;
}
.mobile-show .mobile-pro span.fill {
    background: #78b06f !important;
    display: flex !important;
    height: 10px !important;
    position: relative !important;
}

.mobile-dis {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
}
li.mobile-hide {
    display: none  !important;
}
.content-row .row ul li {
    width: calc(100% / 2) !important;
}
.mobile-show.flex-column {
    flex-direction: column !important;
	margin-bottom: 35px !important;
}
.mobile-show.flex-column h5.mobile-progress {
    width: 100%  !important;
    margin-bottom: 10px !important;
    padding-left: 15px  !important;
}
.mobile-dis span.mobile-pro {
    width: 100% !important;
    margin-right: 10px !important;
}
}
@media(max-width: 480px)
{
	.title {
		font-size: 20px !important;
		margin-bottom: 10px !important;
	}
	.why-consuting-top .title {
    margin-bottom: 10px !important;
}
p {
    font-size: 15px !important;
}
.consulting-box {
    width: 100% !important;
}
.why-consuting-bottom {
   margin-top: 20px !important;
    
}
.consulting-box span {
    min-height: 90px !important;
    margin-bottom: 0 !important;
}
.consulting-box h4 {
    margin-bottom: 10px !important;
}
.consulting-box:last-child {
    margin-bottom: 0 !important;
}
.inner-video span {
    width: 44px !important;
    height: 44px !important;
	margin-top: -44px !important;
}
.inner-video span img {
    width: 16px !important;
}
.video-sec-right {
    padding: 30px 15px 150px !important;
}
.video-sec-left {
    margin: -110px auto 0 !important;
    
}
.service-inner-left span img {
    width: 100px !important;
}
.service-inner-left .title {
    margin-bottom: 10px !important;
}
.service-inner-left p {
    font-size: 15px !important;
}
.right-side-bullet li {
    font-size: 16px !important;
}
.right-side-bullet li {
    font-size: 16px !important;
    padding-left: 40px !important;
}
.right-side-bullet li:before {
    left: 5px !important;
    top: 15px !important;
}
.student-love-us {
    padding-top: 25px !important;
}
.student-testi-left:before, .student-testi-right:before {
	background-size: 40px !important;
}
.student-testi-left, .student-testi-right {
    padding: 0 0px 60px 63px !important;
    width: 73% !important;
}
.student-name {
    align-items: flex-start !important;
    flex-direction: column !important;
}
.student-name span {
    margin-left: 0 !important;
    margin-top: 20px !important;
}
.student-love-us .title {
    margin-bottom: 25px !important;
}
.btn-wrapper .btn-primary {
    padding: 13px 0 !important;
    width: 94% !important;
}
.footer-left ul li:first-child {
    order: 3 !important;
    display: flex !important;
    flex-direction: column !important;
}
.header-banner-left h4 {
    font-size: 16px !important;
    padding-left: 20px !important;
    margin-top: -54px !important;
	margin-bottom: 7px !important;
}
.header-banner-left h3 {
    font-size: 20px !important;
    padding: 0 10px !important;
}
.header-banner-left {
    padding-top: 45px !important;
    padding-bottom: 28px !important;
    
}
.header-banner {
    padding-bottom: 30px !important;
}
.left-content {
    background: #fff !important;
    width: 98% !important;
    margin-left: -5% !important;
    padding: 20px 13px 0 !important;
    transform: rotate(-6deg) !important;
    position: relative !important;
}
.header-banner {
    padding-bottom: 30px !important;
    overflow-x: hidden !important;
}
.video-sec-right {
    width: 94.2% !important;
    padding: 30px 15px 160px !important;
    margin-top: 0 !important;
    order: -1 !important;
    margin-left: -6px !important;
}
.btn {
    padding: 13px 0 !important;
    width: 100% !important;
    text-align: center !important;
	margin-left: auto !important;
    margin-right: auto !important;
}
.before-footer {
    padding: 30px 10px !important;
}
.contact-left li > span {
    width: 90% !important;
}
.contact-left li:nth-child(3) img {
    margin-top: 0 !important;
}
.contact-left li {
    align-items: flex-start !important;
	margin-bottom: 25px !important;
}
.contact-left li img {
   margin-top: 5px !important;
}
.form-field label {
    font-size: 14px !important;
    
}
.form-field input[type="email"] {
    font-size: 14px !important;
}
.contact-left li > span {
    font-size: 15px !important;
    
}
.contact-right {
    width: 92% !important;
    padding: 30px 15px !important;
}
.video-block {
    width: 100% !important;
    margin: 0 0px 30px 0 !important;
}
.video-block:nth-child(3n+3) {
    margin-right: 0 !important;
}
.video-title h4 a {
    font-size: 14px !important;
}
.video-block-wrapper {
    margin-top: 20px !important;
}
.tutorial-block a {
    font-size: 15px !important;
}
.tutorial-block .right-side-bullet li:before {
    background: url(../images/pdf.png) no-repeat !important;
    height: 30px !important;
    width: 42px !important;
    background-size: 20px !important;
    top: 11px !important;
}
.tutorial-block-wrapper {
    margin-top: 20px !important;
}
.content-block-1.con.resource {
   margin-bottom: 40px !important;
}
.image-block a img {
    width: 26px !important;
}
.login-right .student-testi-left:before {
    top: 40px !important;
    left: -45px !important;
}
.login-left .title {
    text-align: left !important;
    margin-bottom: 30px !important;
}
.login-left .form-field-wrapper .form-field.full {
    margin-bottom: 30px !important;
}
.login-wrapper:after {
    height: 55% !important;
}
.forgot a {
    font-size: 12px !important;
}
.login-left .btn-wrapper .btn {
    width: 96% !important;
}
.filter-block:last-child {
    border-right: 1px solid #e5e5e5 !important;
    border: none !important;
    background: #f0f7f8 !important;
}
.filter-block button img {
    margin-left: 20px !important;
}
.filter-block button span:nth-child(2) {
    margin-left: 15px !important;
}
.filter-block button {
    display: flex !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}
.txtpass {
    width: 85%  !important;
    
}
.mobile-show .mobile-pro {
    margin-right: 0 !important;
}
.mobile-show .mobile-pro {
    width: 120px !important;
    margin-right: 25px !important;
}
.detail-box {
    padding: 20px 10px !important;
    
}

.detail-left ul li h4:first-child {
    width: 30% !important;
}
.detail-left ul li h4:last-child {
    width: 15% !important;
}
.detail-left ul li span {
    width: 60% !important;
    margin-right: 20px !important;
}
.video-section .container {
    width: 100% !important;
}
.student-testi-right {
    display: none !important;
}
#header .container {
    width: 100% !important;
}
.header-banner-left {
	margin: 0 auto !important;
    width: 88% !important;
}
}

@media(max-width: 375px)
{
	a.play {
    left: 5px !important;
    
}
} 