.tutorial-video-wrapper {
    margin-bottom: 30px;
}
.tutorial-block-wrapper {
    display: flex;
    justify-content: space-between;
	margin-top: 50px;
}
.tutorial-block {
    position: relative;
    z-index: 99;
	width: 50%;

}
.tutorial-block:nth-child(2)
{
	padding-left: 18%;
}
.tutorial-block a {
    color: #335480;
}
.tutorial-block a:hover
{
	color: #78B06E;
}
.tutorial-block .right-side-bullet li:before {
    
    background: url(../../assets/images/resources/pdf.png) no-repeat;
	height: 30px;
    width: 42px;
    
}
.content-block-1.con.resource {
    height: 450px;
    margin-bottom: 170px;
}
.video-block-wrapper {
    margin-top: 60px;
}
.video-block-wrapper {
    margin-top: 60px;
	display: flex;
    flex-wrap: wrap;
}

.video-block {
    width: calc(100% / 3 - 30px);
    margin: 0 30px 30px 0;
}
.video-block:nth-child(3n+3) {
    margin-right: 0;
}
.video-title
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-top: 12px;
}
.video-title h4 a {
     font-family: 'ITC Avant Garde Std Md';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: #335480;
    max-width: 90%;
	transition: 0.3s all ease-in-out;
}
.video-title h4 a:hover
{
	color: #78b06e;
}
.video-title h5 {
    font-family: 'ITC Avant Garde Std Bk';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #335480;
}
.image-block {
    position: relative;

}
a.play {
    width: 54px;
    height: 54px;
    background: #78b06e;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
	transition: 0.3s all ease-in-out;
}
a.play:hover
{
	background: #335480;
}
.image-block img {
    width: 100%;
    object-fit: cover;
}
.image-block .play img {
    width: auto;
}